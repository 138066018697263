"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePeloAdm = exports.update = void 0;
const core_1 = require("./core");
function update(data) {
    return core_1.put('Funcionario', 'alterarSenha', Object.assign({}, data));
}
exports.update = update;
function updatePeloAdm(data) {
    return core_1.put('Funcionario', 'alterarSenhaPeloAdm', Object.assign({}, data));
}
exports.updatePeloAdm = updatePeloAdm;
