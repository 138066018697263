var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.value.id)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Nova Senha","dense":"","type":"password","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 6); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 20); } ]},model:{value:(_vm.value.senha),callback:function ($$v) {_vm.$set(_vm.value, "senha", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.senha"}})],1):_vm._e(),(_vm.value.id)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Confirmar Nova Senha","dense":"","type":"password","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 6); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 20); },
                    function (v) { return this$1.$validators.string.camposIguais(_vm.value.senha, _vm.value.senha2); } ]},model:{value:(_vm.value.senha2),callback:function ($$v) {_vm.$set(_vm.value, "senha2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.senha2"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }