<template>
  <v-row>
    <v-col cols="12">
      <strong>Dados pessoais</strong>
      <v-divider/>
    </v-col>
    <v-col cols="12" lg="8">
         <v-text-field label="* Nome"
                    dense
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                  ]"
                    v-model.trim="value.nome"/>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field label="CPF"
                    dense
                    placeholder="000.000.000-00"
                    :rules="[
                     this.$validators.string.cpf
                  ]"
                    v-mask="'###.###.###-##'"
                    v-model.trim="value.cpf"/>
    </v-col>
    <v-col cols="12">
      <strong>Dados de acesso</strong>
      <v-divider/>
    </v-col>
    <v-col v-if="this.nivelUsuarioLogado==='ADM'" cols="12" lg="6">
      <lookup-dominio label="* Nível de Acesso"
                      :hidden-clear="true"
                      hint="Selecione o Nível de Acesso"
                      v-model="value.nivel"
                      :type="lookups.nivel"
                      :rules="[
                      this.$validators.notNullOrUndefined
                  ]"/>
    </v-col>
    <v-col v-else cols="12" lg="6">
      {{`Nivel de Acesso`}}
      <br>
      {{value.descricaoNivel}}
    </v-col>
    <v-col cols="12" lg="6">
         <v-text-field label="* Login"
                    dense
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                  ]"
                    v-model.trim="value.login"/>
    </v-col>
    <v-col v-if="!value.id" cols="12" lg="6">
         <v-text-field label="* Senha"
                    dense
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                  ]"
                    v-model.trim="value.senha"/>
    </v-col>
    <v-col cols="12">
      <strong>Dados de Contato</strong>
      <v-divider/>
    </v-col>
    <v-col cols="12">
      <v-text-field label="* Email"
                    dense
                    :rules="[
                      this.$validators.string.required,
                      this.$validators.string.email,
                      v => this.$validators.string.lessThanOrEquals(v, 50),
                  ]"
                    v-model.trim="value.email"/>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field label="* Celular"
                    dense
                    v-mask="'(##) #####-####'"
                    :rules="[
                      this.$validators.string.required,
                  ]"
                    v-model.trim="value.celular"/>
    </v-col>

    <v-col cols="12" md="6">
      <v-text-field label="Telefone"
                    dense
                    v-mask="'(##) ####-####'"
                    v-model.trim="value.telefone"/>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import {mapGetters} from "vuex";

export default {
  name: "FuncionarioForm",
  components: {LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        nivel: ItemDeDominio.NIVEL_ACESSO_USUARIO,
      }
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
}
</script>

<style scoped>

</style>